@tailwind base;
@tailwind components;
@tailwind utilities;

.slick-dots li.slick-active button:before{
    color: #27AE60;
}

.slick-dots li{
    width: 5px;
}
.CountCard .ant-card:not(.ant-card-bordered){
    box-shadow: none;
}

.CountCard .ant-card .ant-card-body {
    padding: 24px 24px 0 0;
}

.ant-drawer-title{
    text-align: center !important;
}

.ant-collapse-header{
    padding: 0 !important;
}
.ant-collapse-item{
    background-color: #fff;
}

.ant-btn-link:not(:disabled):hover{
    color: #27AE60 !important;
}

/* .ant-radio-wrapper {
    margin-inline-end: 45px;
} */

.suggestedLocations .ant-btn-primary:not(:disabled):hover{
    background: #F2F2F2 !important;
}
.ant-upload-list-picture-card .ant-upload.ant-upload-select{
    width: 100% !important;
    height: 135px !important;
    padding: 10px 0 !important;
    margin: 0 ;
}

/* .ant-checkbox-group-item {
    display: block !important;
    margin-right: 0;
} */

.ant-checkbox-group{
    display: flex;
    flex-direction: column;
}
.ant-checkbox-wrapper{
    margin-bottom: 10px;
}

.ant-checkbox-wrapper+.ant-checkbox-wrapper{
    margin-inline-start: 0; 
}

.ant-picker-input.ant-picker-input-active input::placeholder {
 color: #27AE60;
}
.ant-select-selection-overflow{
    flex-wrap: nowrap !important;
}

.ant-col.ant-col-24.ant-form-item-label label{
    justify-content: space-between;
    width: 100%;
}

.campaignstatus .disabled  .ant-radio-checked .ant-radio-inner {
    background-color:red !important;
    border-color: red !important ;
  }

 .sales .ant-tabs-nav-list{
    margin-left: auto;
  }

  .dashboard  .ant-tabs-nav-list{
    margin-left: auto;
  }
  
  .ant-tabs {
    color: #6b7280;
}

.salesDrawer th.ant-table-cell{
    font-size: 12px !important;
    color: #6B7280 !important;
}

.ant-steps-item-title {
    display: flex !important;
    flex-direction: column !important;
}

.ant-steps-item-subtitle{
    margin: 0 !important;
}

.ant-steps.ant-steps-vertical >.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail{
    padding: 32px 0 0px !important;
}

#linechart svg.recharts-surface{
    width: 100% !important;
    height: 350px !important;
  }

  .rewards .ant-image {
      position: unset;
      display: initial;
  }
 
  .location .ant-image {
    height: 48px;
    width: 48px;
  }

  .emailVerification .ant-card:not(.ant-card-bordered) {
    box-shadow: none;
  }
.cardShadow{
    @apply shadow-lg !important
}
.menuItem.fill path{
    @apply fill-charcoal
}
.menuItem.fill:hover path, .menuItem.active.fill path{
    @apply fill-green
}
.menuItem.stroke path{
    @apply stroke-charcoal
}

.menuItem.stroke:hover path, .menuItem.active.stroke path{
    @apply stroke-green
}
.menuItem.isdisabled path{
    @apply stroke-accentGray
}
.text-md-l5-semibold{
    @apply text-base font-semibold leading-5;
}

.text-lg-l5-semibold{
    @apply text-lg font-semibold leading-5;
}
.text-sm-l5-normal{
    @apply text-13 font-normal leading-5;
  }
.text-sm-l5{
    @apply text-sm font-normal leading-5;
}

.textHeadings {
    @apply font-medium text-base
}
